<template>
    <div>
      Location tracking: <input type="checkbox" v-model="automatic" @change="toggleAutomatic"/>
    </div>
</template>
<script>
import EventBus from '../event-bus.js'

export default {
  data() {
    return {
      lat: 0,
      lon: 0,
      status: 'waiting',
      automatic: false,
      watchId: 0
    }
  },


  methods: {
    toggleAutomatic(){
        navigator.geolocation.clearWatch(this.watchId);
        //this.automatic = !this.automatic;
        if(this.automatic){
          //console.log('startwatch');
          //console.log(this.watchId);
          this.watchId = navigator.geolocation.watchPosition(
            position => {
              console.log('position');
              console.log(position);
              EventBus.$emit("userLocation",{lat:position.coords.latitude,lng:position.coords.longitude});
            },
            error => {
              this.status = error.message;
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0
            },
         );
      

        
    }
  }

  }
}
</script>