<template>
    <div class="tut">tut</div>
    <button @click="ping">tut</button>
</template>

<script>
import * as Tone from 'tone'

export default {
    data(){
        return{
            name:'TutTut'
        }
    },

    methods:{
        pinger(){
            //return new Tone.Synth().toDestination();

            //const panner1 = new Tone.Panner(0.6).toDestination();

            //const panner2 = new Tone.Panner(-0.6).toDestination();

            return [
                //new Tone.FatOscillator("C2","sawtooth",2).connect(panner1),
                //new Tone.FatOscillator("C3","sawtooth",2).connect(panner2),
                new Tone.Player('https://ia803007.us.archive.org/1/items/christmasshortworks_2014_1412_librivox/csw14_08_tomten_64kb.mp3')

            ];
        },
        ping(){         
            //play a middle 'C' for the duration of an 8th note
            //this.pinger().triggerAttackRelease("C4", "8n"); 
            for(const a of this.pinger())
                a.start();
        } 
    }
}
</script>
