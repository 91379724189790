<template>
    <div>
        <button @click="setMapModeUserLocation">Place user</button>        
        <button @click="setMapModeAudioLocation">Place audio</button>
    </div>
</template>
<script>
import EventBus from '../event-bus.js'

export default {

    methods: {
        setMapModeUserLocation(){
            EventBus.$emit("setMapMode",'placeUser');
        },
        setMapModeAudioLocation(){
            EventBus.$emit("setMapMode",'placeAudio');
        },

    }
}
</script>