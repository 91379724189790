<template>
  <LeafletMap/>
  <ControlArea/>
</template>

<script>
import LeafletMap from './components/LeafletMap.vue';
import ControlArea from './components/ControlArea.vue';



export default {
  name: 'App',
  components: {
    LeafletMap,
    ControlArea
}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 6px;
}
.controlarea {
  height: 20%;
}
</style>
