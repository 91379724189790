<template>
    <div>
        <button @click="centerUser">center</button>
    </div>
</template>
<script>
import EventBus from '../event-bus.js'

export default {

    methods: {
        centerUser(){
            EventBus.$emit("centerUser");
        }

    }
}
</script>