<template>
    <div class="controlarea">
        <GeoLoc/><CenterMap/><SetMapMode/><TutTut/>
    </div>

</template>
<script>
import GeoLoc from './GeoLoc.vue';
import TutTut from './TutTut.vue';
import CenterMap from './CenterMap.vue';
import SetMapMode from './SetMapMode.vue';

export default {
    data() {
        return {
         
        };
    },
    methods: {

    },
    components: { GeoLoc, TutTut, CenterMap, SetMapMode }
}
</script>
