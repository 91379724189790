<template>
<div style="height: 400px; width: 100%;">
<l-map
      v-model="zoom"
      v-model:zoom="zoom"
      :center="center"
    @click="clickOnMap"
    >
    <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ></l-tile-layer>
      <l-marker :lat-lng="[userLocation.lat|| defaultLocation.lat, userLocation.lng|| defaultLocation.lng]">
        <!-- <l-icon icon-url='https://placekitten.com/90/90' icon-size=12 />s -->
      <l-icon icon-url='/img/icons/by.png' :icon-size="iconSize" />s
      </l-marker>
      <l-marker v-for="a in audioPoints" :key="a.id" :lat-lng="a.coordinates" @click="clickOnAudioPoint(a.id)"
      :clickable="true" :draggable="true" >
      <l-icon
          icon-url="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png">
      </l-icon>
    </l-marker>
  </l-map>
</div>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
import EventBus from '../event-bus.js'
import "leaflet/dist/leaflet.css"
import { LMap,LTileLayer,LMarker,LIcon } from "@vue-leaflet/vue-leaflet";
import { isDefined } from 'tone';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon

  },
/*   computed: {
    center() {
      return {
        lat: this.userLocation.lat || this.defaultLocation.lat,
        lng: this.userLocation.lng || this.defaultLocation.lng };
    }
  }, */
  props: {
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 59.27736014554536,
        lng: 18.13251614570618
      })
    }
  },

  data() {
    return{
      mode: 'standard', 
      center: this.defaultLocation,
      zoom: 16,
      userLocation: {},
      iconSize: [22,22],
      audioPoints : []
      }
  },
  mounted() {
    EventBus.$on("userLocation", (pos) => {
      console.log('received');
      console.log(pos)
        this.userLocation = {
          lat: pos.lat,
          lng: pos.lng
          };

    });


    EventBus.$on("centerUser", () => {
      this.center = this.userLocation;

    });

    EventBus.$on("setMapMode", (mode) => {
      console.log('received '+mode);
      this.mode = mode;
    });

    EventBus.$on("audioLocation", (pos) => {
      console.log('audioLocation received ');
      this.audioPoints.push({id:uuidv4(),coordinates:pos});
      console.log(pos);

    });

  },

  methods:{
    clickOnAudioPoint(event){
      console.log('click on audio point');
      console.log(event);
    },

    clickOnMap(event){
      if(this.mode === 'placeUser' && isDefined(event.latlng)){
        EventBus.$emit("userLocation", {lat:event.latlng.lat,lng:event.latlng.lng});
        this.mode = 'standard';
      }
      else if(this.mode === 'placeAudio' && isDefined(event.latlng)){
        EventBus.$emit("audioLocation", {lat:event.latlng.lat,lng:event.latlng.lng});
        this.mode = 'standard';
      }

    }

  }
};
</script>
<style scoped>
.main-wrapper {
  display: flex;
}
.menu {
  display: flex;
  flex-direction: column;
}
.menu a {
  padding: 0.25rem 0.5rem;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}
.menu .router-link-exact-active {
  background-color: lightgray;
}
.map-wrapper {
  height: 75vh;
  width: 100%;
}
</style>